import { Button } from "./Button"
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

export const RoomCard = ({ roomDetails }) => {
    const navigate = useNavigate();

    const showRoomDetails = (roomType) => {
        navigate(`/roomDetail/${roomType}`)
    }

    return (
        <motion.div
            className="text-white flex flex-col mb-4 items-center justify-center"
            initial={{ opacity: 1, y: 100 }}
            whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0, duration: 0.8 },
            }}
            viewport={{ once: true, amount: 0 }}>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 p-2 gap-4 justify-items-center">
                {roomDetails.map((detail, index) => (
                    <div key={index} onClick={() => showRoomDetails(detail.roomType)} className="max-w-md flex flex-col shadow-xl bg-card shadow-right rounded-md hover:cursor-pointer border-4 border-transparent hover:border-clickable">
                        <img className="rounded-t-lg" src={detail.source} alt="" title={detail.title} loading="eager" width="1179" height="1554" />
                        <div className="flex flex-col gap-4 p-4 justify-between grow">
                            <div>
                                <h5 className="mb-2 text-2xl text-black font-bold text-center">{detail.roomType}</h5>
                                <p className="mb-3 font-normal text-gray-700 text-justify">{detail.description}</p>
                            </div>
                            <Button text="View Details" />
                        </div>
                    </div>
                ))
                }
            </div >
        </motion.div>
    )
}