export const WhatsAroundUs = () => {
    return (
        <div className="max-w-5xl text-lg bg-card p-4 rounded-md shadow-xl ">
            <div className="flex flex-col justify-center items-center">
                <h2 className="text-2xl font-bold text-center uppercase text-gray-700">What's around us?</h2>
                <div className="w-[280px] mb-4 border-2  border-b-[#bb9f82] " />
            </div>

            <p className="mb-4">
                Our residences are within walking distance of Leuven’s vibrant city center. There you will find a wide range of stores, culture, sports, restaurants, cafes, services.
            </p>

            <div className="ml-8">
                <ul className="list-disc">
                    <li> <strong>Train station of Leuven</strong>: 5 min cycling</li>

                    <li> <strong>Campus Arenberg</strong>: 11 min cycling </li>

                    <li> <strong>Campus GroepT</strong>: 5 min cycling </li>

                    <li> <strong>Sportkot</strong>: 7 min cycling </li>

                    <li> <strong>Bus stop</strong>: 2 min walk</li>

                    <li> <strong>Sportoase Leuven</strong>: 8 min cycling</li>

                    <li> <strong>Oudt Markt</strong>: few min walk</li>

                    <li> <strong>Botanical garden</strong>: 10 min walk </li>
                </ul>
            </div>
        </div>
    )
}