import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios";

export const getRoomCosts = createAsyncThunk(
    "accommodation/getRoomCosts",
    async () => {
        const { data } = await axios.get("https://www.api.residentiereginapacis.be/api/roomCosts")
        return data;
    }
)

const initialState = {
    isLoading: false,
    isError: false,
    error: "",
    success: false,
    roomCosts: [],
};

const roomCostsSlice = createSlice({
    name: "accommodation",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getRoomCosts.pending, (state) => {
            state.isLoading = true
        })

        builder.addCase(getRoomCosts.fulfilled, (state, action) => {
            state.isLoading = false;
            state.roomCosts = action.payload;
            state.success = true;
        })

        builder.addCase(getRoomCosts.rejected, (state) => {
            state.isLoading = false;
            state.isError = true;
            state.error = "Request failed with status code 404!";
        })
    }
})

export default roomCostsSlice.reducer