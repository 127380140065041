import { Link, NavLink } from "react-router-dom";
import LogoSvg from "../assets/logo/trace.svg";
import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";

export const Header = () => {
  const [hideNav, setHideNav] = useState(true);

  const activeNavButtonStyle = "text-xl text-clickable py-2 px-3 md:p-0 rounded select-none";
  const inactiveNavButtonStyle = "text-lg animate-bounce py-2 px-3 md:p-0 text-gray-900 rounded md:hover:bg-transparent md:border-0 hover:text-clickable select-none";

  const contactActiveButtonStyle = "border-2 p-4 rounded-md bg-clickable text-white uppercase";
  const contactInActiveButtonStyle = "border-2 p-4 rounded-md bg-black text-white uppercase hover:bg-clickable";
  const getContactButtonStyle = (isActive) => {
    if (hideNav === false && isActive) {
      return activeNavButtonStyle
    } else if (hideNav === false && !isActive) {
      return inactiveNavButtonStyle
    } else if (hideNav && isActive) {
      return contactActiveButtonStyle
    } else if (hideNav && !isActive) {
      return contactInActiveButtonStyle
    }
  }

  return (
    <header className="bg-headerBackground">
      <div className="max-w-[1701px] flex flex-wrap items-center justify-between mx-auto px-4">
        <div className="w-64 my-4">
          <Link
            to="/"
            onClick={() => {
              setHideNav(true);
            }}
          >
            <img src={LogoSvg} alt="Regina Pacis Logo" title="Recina Pacis main logo" loading="eager" width="264" height="164" />
          </Link>
        </div>

        <button
          onClick={() => setHideNav(!hideNav)}
          data-collapse-toggle="navbar-solid-bg"
          type="button"
          className="inline-flex items-center p-2 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 cus:outline-none focus:ring-2 focus:ring-gray-200 "
          aria-controls="navbar-solid-bg"
          aria-expanded="false"
        >
          <span className="sr-only">Open main navigation</span>
          <GiHamburgerMenu size="30px" />
        </button>

        <div
          className={`${hideNav ? "hidden" : ""} w-full md:block md:w-auto`}
          id="navbar-solid-bg"
        >
          <ul className="flex flex-col font-medium mt-4 rounded-lg max-md:items-center md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent">
            <li>
              <NavLink
                to="/"
                onClick={() => setHideNav(true)}
                className={({ isActive }) => {
                  return isActive
                    ? activeNavButtonStyle
                    : inactiveNavButtonStyle;
                }}
                aria-current="page"
              >
                Regina Pacis
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                onClick={() => setHideNav(true)}
                className={({ isActive }) => {
                  return isActive
                    ? activeNavButtonStyle
                    : inactiveNavButtonStyle;
                }}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/accommodation"
                onClick={() => setHideNav(true)}
                className={({ isActive }) => {
                  return isActive
                    ? activeNavButtonStyle
                    : inactiveNavButtonStyle;
                }}
              >
                Accommodation
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact"
                onClick={() => setHideNav(true)}
                className={({ isActive }) => getContactButtonStyle(isActive)}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};
