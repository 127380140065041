import { Link } from "react-router-dom";
import { TfiEmail } from "react-icons/tfi";
import { IoCallSharp } from "react-icons/io5";
import { FaMapLocationDot } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";

export const Footer = () => {
  return (
    <footer className="bg-footerBackground overflow-hidden px-4">
      <div className="mx-auto max-w-screen-xl py-4 lg:py-5">
        <div className="grid max-md:grid-cols-2 md:grid-cols-3 sm:justify-between max-sm:flex-col max-sm:gap-8">
          <div className="max-md:text-sm">
            <h2 className="underline mb-4 text-md font-semibold text-gray-900 uppercase select-none">
              Links
            </h2>
            <ul>
              <li className="mb-2">
                <Link to="/" className="hover:underline">
                  Regina Pacis
                </Link>
              </li>

              <li className="mb-2">
                <Link to="/about" className="hover:underline">
                  About
                </Link>
              </li>

              <li className="mb-2">
                <Link to="/accommodation" className="hover:underline">
                  Accommodation
                </Link>
              </li>

              <li>
                <Link to="/contact" className="hover:underline">
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          <div className="justify-self-center max-md:text-sm max-sm:mr-14 group relative">
            <h2 className="underline mb-4 text-mg font-semibold text-gray-900 uppercase select-none">
              Contact Us
            </h2>
            <a
              href="https://maps.app.goo.gl/BiVZdfY8Gtp1QBLJ8"
              className="hover:underline hover:cursor-pointer"
              target="_blank"
              rel="noreferrer"
            >
              <span className="flex gap-2">
                <FaMapLocationDot size="30px" />
                Dirk Boutslaan 64, 3000 Leuven, Belgium
              </span>
            </a>

            <span className="flex items-center gap-2 hover:cursor-pointer my-2">
              <TfiEmail size="30px" />
              <a
                className="hover:underline"
                href={`mailto:reginapacis@studentcomfort.be`}
              >
                reginapacis@studentcomfort.be
              </a>
            </span>
            <div className="flex gap-2">
              <IoCallSharp size="33px" />
              <p>0470 56 72 72</p>
            </div>
          </div>

          <div className="max-md:text-sm max-md:mt-8 md:justify-self-end md:align-self-end">
            <h2 className="underline mb-4 text-md font-semibold text-gray-900 uppercase select-none">
              Follow us
            </h2>
            <ul className="flex flex-row gap-4 max-sm:flex-col">
              <a
                href="https://www.facebook.com/profile.php?id=61558532731586"
                target="_blank"
                rel="noreferrer"
              >
                <FaFacebook
                  size="35px"
                  className="text-blue-600 hover:text-[blue]"
                />
              </a>
            </ul>
          </div>
        </div>

        <hr className="my-4 border-gray-200 sm:mx-auto lg:my-8" />

        <div className="flex justify-between max-lg:flex-col justify-center max-lg:gap-4">
          <span className="text-sm text-gray-500 text-center">
            © {" "}
            <Link to="/" className="hover:underline">
              REGINA PACIS
            </Link>
          </span>

          <span className="text-sm text-gray-500 text-center">
            BE 0871.448.889 - Residentie Regina Pacis - Part of StudentComfort
            BE 0448.898.083
          </span>
        </div>
      </div>
    </footer>
  );
};
