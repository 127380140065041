import { useState } from "react";
import { Button } from "./Button";
import { PiSpinnerThin } from "react-icons/pi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useFormik } from "formik";
import { EmailFormValidation } from "../validations/EmailFormValidation";
import { motion } from "framer-motion";
import axios from "axios";

const initialValues = {
  name: "",
  email: "",
  phoneNumber: "",
  fieldOfStudy: "",
  subject: "",
  message: "",
};

export const EmailForm = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: EmailFormValidation,
      onSubmit: (values, { resetForm }) => {
        setShowSpinner(true);
        axios.post('https://www.api.residentiereginapacis.be/api/email/sendEmail/', values)
          .then(response => {
            console.log(response)
            setEmailSent(true)
            setShowSpinner(false)
            resetForm();
          })
          .catch(error => {
            console.warn(error)
            setShowSpinner(false)
          })
      },
    });

  return (
    <div>
      <div className="flex items-center justify-center mt-4 max-sm:text-center p-2">
        <motion.div
          className="text-white flex flex-col items-center justify-center"
          initial={{ opacity: 1, y: 50 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0, duration: 0.8 },
          }}
          viewport={{ once: true, amount: 0 }}>
          <div className="max-w-[800px] rounded bg-green-300 p-4">
            <h2 className="text-2xl text-gray-700 select-none">
              <strong>
                Only students can rent rooms in this residentie
              </strong>
            </h2>
          </div>
        </motion.div>
      </div>

      <div className="flex justify-center items-center">
        {showSpinner && <PiSpinnerThin className="animate-spin" size="200px" />}
        {!emailSent && !showSpinner && (
          <form className="w-full mx-auto" onSubmit={handleSubmit}>
            <div className="flex flex-col justify-center items-center gap-4 mt-5 mb-4 bg-card shadow-xl rounded-md p-2">

              {/* Name text field area */}
              <div>
                {errors.name && touched.name ? (
                  <p className="text-rose-600 text-sm">{errors.name}</p>
                ) : null}
                <input
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  type="text"
                  name="name"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block max-sm:w-[350px] sm:w-[600px] md:w-[700px] lg:w-[800px] h-14 p-2.5"
                  placeholder="Name (Required)"
                />
              </div>

              {/* Email text input area */}
              <div>
                {errors.email && touched.email ? (
                  <p className="text-rose-600 text-sm">{errors.email}</p>
                ) : null}
                <input
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  type="text"
                  name="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[800px] max-sm:w-[350px] sm:w-[600px] md:w-[700px] lg:w-[800px] h-14 p-2.5"
                  placeholder="Email (Required)"
                />
              </div>

              {/* Phone number text input area */}
              <div>
                {errors.phoneNumber && touched.phoneNumber ? (
                  <p className="text-rose-600 text-sm">{errors.phoneNumber}</p>
                ) : null}
                <input
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  type="text"
                  name="phoneNumber"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[800px] max-sm:w-[350px] sm:w-[600px] md:w-[700px] lg:w-[800px] h-14 p-2.5"
                  placeholder="Phone number (Required)"
                />
              </div>

              {/* Field of study text input area */}
              <div>
                {errors.fieldOfStudy && touched.fieldOfStudy ? (
                  <p className="text-rose-600 text-sm">{errors.fieldOfStudy}</p>
                ) : null}
                <input
                  value={values.fieldOfStudy}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  type="text"
                  name="fieldOfStudy"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[800px] max-sm:w-[350px] sm:w-[600px] md:w-[700px] lg:w-[800px] h-14 p-2.5"
                  placeholder="Field Of Study (Required)"
                />
              </div>

              {/* Subject text input area */}
              <div>
                {errors.subject && touched.subject ? (
                  <p className="text-rose-600 text-sm">{errors.subject}</p>
                ) : null}
                <input
                  value={values.subject}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  type="text"
                  name="subject"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[800px] max-sm:w-[350px] sm:w-[600px] md:w-[700px] lg:w-[800px] h-14 p-2.5"
                  placeholder="Subject (Required)"
                />
              </div>

              {/* Message text input area */}
              <div>
                {errors.message && touched.message ? (
                  <p className="text-rose-600 text-sm">{errors.message}</p>
                ) : null}
                <textarea
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  id="message"
                  name="message"
                  rows="10"
                  className="block p-2.5 min-h-72 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 max-sm:w-[350px] sm:w-[600px] md:w-[700px] lg:w-[800px]"
                  placeholder="Message (Required)"
                ></textarea>
              </div>
            </div>

            <Button text="Send" />
          </form>
        )}

        {emailSent && (
          <>
            <div className="flex flex-col gap-2">
              <IoMdCheckmarkCircleOutline
                className="text-green-500 ml-4"
                size="150px"
              />
              <span>Email sent succesfully!</span>
              <Button text="New email" onClick={() => setEmailSent(false)} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
