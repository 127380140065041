export const AboutReginaPacis = () => {
    return (
        <div className="bg-card p-4 rounded-md text-lg shadow-xl ">
            <div className="flex flex-col justify-center items-center rounded-md ">
                <h1 className="text-2xl font-bold text-center uppercase text-gray-700">About Residentie Regina Pacis</h1>
                <div className="w-[415px] mb-4 border-2  border-b-[#bb9f82] " />
            </div>

            <div className="ml-8">
                <ul className="list-disc">
                    <li>Newly renovated & good build</li>
                    <li>Energy efficient</li>
                    <li>Central garden</li>
                    <li>Furnished rooms</li>
                    <li>Two elevator</li>
                    <li>Laundary service</li>
                    <li>Secure bicycle parking area</li>
                    <li>Sofa for relaxing</li>
                    <li>Playing area (Billiard, foosball and others..)</li>
                    <li>Two kitchen every floor and a large kitchen between first and second floor</li>
                    <li>Free to use plates, eating utensils, tables, chairs in every kitchen</li>
                    <li>Dish washer in every kitchen</li>
                </ul>
            </div>
        </div>
    )
}