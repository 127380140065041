import { TfiEmail } from "react-icons/tfi";
import { IoCallSharp } from "react-icons/io5";
import { FaMapLocationDot } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";

export const ContactDetails = () => {
  return (
    <div className="flex flex-col justify-between text-gray-700 bg-card shadow-xl shadow-right p-2 rounded-md lg:p-4 h-[590px] mb-5">
      <div className="flex flex-col gap-2 justify-between">
        <div className="flex flex-col items-center">
          <h2 className="text-2xl text-black font-bold">CONTACT DETAILS</h2>
          <div className="mb-4 h-[2px] w-[214px] bg-[#bb9f82]" />
        </div>

        <a
          href="https://maps.app.goo.gl/BiVZdfY8Gtp1QBLJ8"
          className="hover:underline hover:cursor-pointer"
          target="_blank"
          rel="noreferrer"
        >
          <span className="flex gap-2">
            <FaMapLocationDot size="30px" />
            Dirk Boutslaan 64, 3000 Leuven, Belgium
          </span>
        </a>

        <span className="flex items-center gap-2 hover:cursor-pointer my-2">
          <TfiEmail size="30px" />
          <a
            className="hover:underline"
            href={`mailto:reginapacis@studentcomfort.be`}
          >
            reginapacis@studentcomfort.be
          </a>
        </span>
        <div className="flex gap-2">
          <IoCallSharp size="33px" />
          <p>0470 56 72 72</p>
        </div>
      </div>

      <a
        className="w-10"
        href="https://www.facebook.com/profile.php?id=61558532731586"
        target="_blank"
        rel="noreferrer"
      >
        <FaFacebook size="35px" className="text-blue-600 hover:text-[blue]" />
      </a>
    </div>
  );
};
