
import { RoomCard } from "../components";
import { Helmet } from 'react-helmet-async'

export const Accommodation = () => {
  const roomDetails = [
    { source: "/assets/comfortPlus/img2.jpg", roomType: "Comfort", description: "Your stylish retreat awaits in our Comfort Rooms, featuring modern furnishings and all the essentials for a comfortable stay. Relax in a space tailored to meet your needs.", title: "Comfort room" },
    { source: "/assets/comfortPlus/img4.jpg", roomType: "Comfort Plus", description: "Elevate your experience with our Comfort Plus Rooms, offering additional space for added convenience. Experience modern living at its finest with all the comforts of home.", title: "Comfort plus room" },
    { source: "/assets/comfortPlus/img3.jpg", roomType: "Duplex", description: "For those seeking a shared living experience with friends or a lot of space, our Duplex Suites provide a spacious and stylish environment.", title: "Duplex room" },
  ];

  return (
    <main>

      <Helmet>
        <title> ACCOMMODATION | Regina Pacis </title>
        <meta name="keywords" content="studentenhuisvesting, studentenkamers, studentenwoning, kamers te huur Leuven, studentenhuis Leuven, studio huren Leuven, studentenkamer Leuven, studentenappartement, studentenresidentie, studentenflat Leuven, kot te huur, kamers voor studenten, goedkope studentenkamers, studentenkoten Leuven, huur studentenkamer, Dirk Boutslaan 64, 3000 Leuven, Belgium, BELGIUM, studentenverblijf, studentenkamer vinden, koten Leuven, studentenstudios, studentenwoonruimte, student housing, student accommodation, student rooms, rooms for rent Leuven, student apartments, student studio, student residence, student flat Leuven, student rental, student housing Leuven, cheap student rooms, student lodgings, student dorms, student living, student flats, student stay, find student room, Leuven student housing, student studios, student living space" />
        <meta name="description" content="Stylish rooms with modern furnishings, offering comfort and convenience. Choose from cozy Comfort Rooms, spacious Comfort Plus, or luxurious Duplex Suites for groups or families." />
        <link rel="canonical" href="https://www.residentiereginapacis.be/accommodation" />
      </Helmet>

      <div className="flex justify-center p-2 max-sm:text-lg sm:text-xl flex-col items-center gap-4 select-none">
        <p className="text-center">
          Welcome to <span className="text-textColor font-bold">Residentie Regina Pacis</span>, your modern student accommodation destination in the heart of <strong>Leuven.</strong>
        </p>
        <p className="text-center">
          Designed with modern flair, our establishment offers <strong>three room types</strong>:
        </p>
        <p className="underline text-clickable hover:">
          <a href="https://my.matterport.com/show/?m=ncTjh1PNFtt" target="_blank" rel="noreferrer"> VIEW ROOM IN 3D</a>
        </p>
      </div>

      {
        <RoomCard roomDetails={roomDetails} />
      }

    </main>
  );
};
