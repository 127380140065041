export const comfortRoomDetails =
    [
        {
            roomType: "Comfort",
            title: "About the room",
            features:
            {
                title: "Features",
                details:
                    [
                        "Furnished",
                        "Bathroom & Toilet",
                        "Double bed (without mattress)",
                        "Breakfast corner (microwave + refrigerator)",
                        "Closet",
                        "Desk & Chair",
                        "Coat rack",
                        "Curtens",
                        "Intercom"
                    ]
            },
            priceRange:
            {
                title: "Price Range",
                priceRange: "€530 - €625",
            },
            extra:
            {
                title: "Extra",
                price: "€110",
                details: ["Heating", "Water", "Electricity", "Wifi", "Cleaning common area", "Garbage"]
            },
            cityTax:
            {
                title: "City Tax",
                price: "€114",
            }
        }
    ]

export const comfortPlusRoomDetails =
    [
        {
            roomType: "Comfort Plus",
            title: "About the room",
            features:
            {
                title: "Features",
                details:
                    [
                        "Furnished",
                        "Bathroom & Toilet",
                        "Double bed (without mattress)",
                        "Breakfast corner (microwave + refrigerator)",
                        "Closet",
                        "Desk & Chair",
                        "Coat rack",
                        "Curtens",
                        "Intercom"
                    ]
            },
            priceRange:
            {
                title: "Price Range",
                priceRange: "€625 - €665",
            },
            extra:
            {
                title: "Extra",
                price: "€110",
                details: ["Heating", "Water", "Electricity", "Wifi", "Cleaning common area", "Garbage"]
            },
            cityTax:
            {
                title: "City Tax",
                price: "€114",
            }
        }
    ]

export const duplexRoomDetails =
    [
        {
            roomType: "Duplex",
            title: "About the room",
            features:
            {
                title: "Features",
                details:
                    [
                        "Furnished",
                        "Bathroom & Toilet",
                        "Double bed (without mattress)",
                        "Breakfast corner (microwave + refrigerator)",
                        "Closet",
                        "Desk & Chair",
                        "Coat rack",
                        "Curtens",
                        "Intercom"
                    ]
            },
            priceRange:
            {
                title: "Price Range",
                priceRange: "€765 - €865",
            },
            extra:
            {
                title: "Extra Cost",
                price: "€110",
                details:
                    [
                        "Heating",
                        "Water",
                        "Electricity",
                        "Wifi",
                        "Cleaning common area",
                        "Garbage"
                    ]
            },
            cityTax:
            {
                title: "City Tax",
                price: "€114",
            }
        }
    ]