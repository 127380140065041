import { useParams } from "react-router-dom"
import { Gallery, AboutRoom } from "../components";
import { comfortRoomDetails, comfortPlusRoomDetails, duplexRoomDetails } from "../data/RoomData";

export const RoomDetails = () => {
    const images = [
        { source: "/assets/comfortPlus/img1.jpg" },
        { source: "/assets/comfortPlus/img2.jpg" },
        { source: "/assets/comfortPlus/img3.jpg" },
        { source: "/assets/comfortPlus/img4.jpg" },
        { source: "/assets/comfortPlus/img5.jpg" },
        { source: "/assets/comfortPlus/img6.jpg" },
        { source: "/assets/comfortPlus/img7.jpg" },
    ];

    const params = useParams();

    let selectedRoomDetails;
    switch (params.roomType) {
        case "Comfort":
            selectedRoomDetails = comfortRoomDetails
            break;
        case "Comfort Plus":
            selectedRoomDetails = comfortPlusRoomDetails
            break;
        case "Duplex":
            selectedRoomDetails = duplexRoomDetails
            break;
        default:
            selectedRoomDetails = []
    }

    return (
        <main>
            <div className="bg-textColor">
                <h1 className="text-center text-white p-4 text-4xl select-none uppercase">{params.roomType}</h1>
            </div>

            <div className="flex gap-2 xl:justify-center max-xl:flex-col overflow-hidden max-xl:items-center">
                <Gallery images={images} />
                < AboutRoom roomDetail={selectedRoomDetails} />
            </div>
        </main>
    )
}