import { HomeCard } from "../components"

export const Hero = () => {
    return (
        <div>
            <div className="flex max-lg:flex-col-reverse gap-2 grow">
                <HomeCard />
                <img className="rounded shadow-2xl
            max-lg:w-[900px] 
            max-lg:h-[400px] 
            max-md:h-[300px] 
            max-sm:h-[250px] 
            lg:w-[1800px] 
            h-[900px]" src="../assets/mainBuildingImg2.png" alt="Main building" title="Regina Pacis main building top view image" loading="eager" />
            </div >
        </div>
    )
}