import { useState } from "react"
import { motion } from "framer-motion"

export const Gallery = ({ images }) => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(0)
    const nextImage = () => {
        var nextImageIndex = (selectedImageIndex + 1) % images.length
        setSelectedImageIndex(nextImageIndex);
    }

    const prevImage = () => {
        setSelectedImageIndex((selectedImageIndex - 1 + images.length) % images.length);
    }

    return (
        <div>
            <motion.div
                className="flex max-md:flex-col items-center"
                initial={{ opacity: 1, y: 100 }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: { delay: 0, duration: 0.8 },
                }}>

                <div className="relative w-[680px] h-auto p-4 
                max-sm:p-2 
                max-sm:w-[600px] 
                max-[580px]:w-[550px] 
                max-[535px]:w-[500px] 
                max-[479px]:w-[475px] 
                max-[465px]:w-[460px] 
                max-[446px]:w-[440px] 
                max-[431px]:w-[430px]
                max-[428px]:w-[425px]
                max-[425px]:w-[415px]">
                    <img className="rounded-md" src={images[selectedImageIndex].source} alt="" />

                    <button type="button" onClick={() => prevImage()} className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-5 cursor-pointer group focus:outline-none" data-carousel-prev>
                        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                            <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                            </svg>
                            <span className="sr-only">Previous</span>
                        </span>
                    </button>
                    <button type="button" onClick={() => nextImage()} className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-5 cursor-pointer group focus:outline-none" data-carousel-next>
                        <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                            <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                            </svg>
                            <span className="sr-only">Next</span>
                        </span>
                    </button>
                </div>

                <div className={`grid ${images.length > 7 && images.length <= 16 ? "grid-cols-2" : ""}
            ${images.length > 16 ? "grid-cols-3" : ""}
             max-md:grid-cols-5 max-sm:grid-cols-4 max-[580px]:grid-cols-5 gap-2`}>
                    {
                        images.map((images, index) => {
                            return (
                                <div key={index} className="w-[100px] max-sm:w-[73px] md:w-[70px] hover:cursor-pointer">
                                    <img onClick={() => setSelectedImageIndex(index)} key={index} className={`h-auto max-w-full rounded-md ${index === selectedImageIndex ? "border-4 border-clickable" : ""}`} src={images.source} alt="" />
                                </div>
                            )
                        })
                    }
                </div>
            </motion.div >
        </div>
    )
}
