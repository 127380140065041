import { WhatsAroundUs, AboutReginaPacis, Button } from "../components"
import { motion } from "framer-motion"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet-async'

export const AboutUs = () => {

    const images = [
        { source: "/assets/leuven/img7.jpg", title: "Leuven train station" },
        { source: "/assets/leuven/img8.jpg", title: "Universiteitsbibliotheek en bibliotheektoren" },
        { source: "/assets/leuven/img9.jpg", title: "Leuven" },
        { source: "/assets/leuven/img10.jpg", title: "Front of Historic Leuven Town Hall" },
        { source: "/assets/leuven/img11.jpg", title: "Leuven train station second" },
        { source: "/assets/leuven/img12.jpg", title: "Town Hall Leuven front view" },
    ];

    return (
        <main>
            <Helmet>
                <title> ABOUT | Regina Pacis </title>
                <meta name="keywords" content="studentenhuisvesting, studentenkamers, studentenwoning, kamers te huur Leuven, studentenhuis Leuven, studio huren Leuven, studentenkamer Leuven, studentenappartement, studentenresidentie, studentenflat Leuven, kot te huur, kamers voor studenten, goedkope studentenkamers, studentenkoten Leuven, huur studentenkamer, Dirk Boutslaan 64, 3000 Leuven, Belgium, BELGIUM, studentenverblijf, studentenkamer vinden, koten Leuven, studentenstudios, studentenwoonruimte, student housing, student accommodation, student rooms, rooms for rent Leuven, student apartments, student studio, student residence, student flat Leuven, student rental, student housing Leuven, cheap student rooms, student lodgings, student dorms, student living, student flats, student stay, find student room, Leuven student housing, student studios, student living space, newly renovated, energy efficient, central garden, furnished rooms, two elevators, laundry service, secure bicycle parking, relaxing sofa, playing area billiards foosball, two kitchens per floor, large kitchen between floors, free plates utensils tables chairs, dishwasher in every kitchen, walking distance Leuven city center, stores culture sports restaurants cafes services, train station Leuven 5 min cycling, Campus Arenberg 11 min cycling, Campus GroepT 5 min cycling, Sportkot 7 min cycling, bus stop 2 min walk, Sportoase Leuven 8 min cycling, Oude Markt few min walk, botanical garden 10 min walk" />
                <meta name="description" content="About Residentie Regina Pacis: Newly renovated, energy-efficient student housing with a central garden, furnished rooms, laundry, secure bike parking, and leisure areas." />
                <link rel="canonical" href="https://www.residentiereginapacis.be/about" />
            </Helmet>

            <div className="flex justify-center max-[1380px]:flex-col max-[1380px]:items-center gap-2 p-4">
                <img className="w-[1022px] rounded-md shadow-2xl" src="../assets/mainBuildingImg1.png" alt="Lei second angle" title="Residentie Regina Pacis top view" loading="eager" />

                <motion.div
                    initial={{ opacity: 1, y: 200 }}
                    whileInView={{
                        opacity: 1,
                        y: 0,
                        transition: { delay: 0, duration: 0.9 },
                    }}
                    viewport={{ once: true, amount: 0 }}>
                    <div className="flex flex-col gap-2">
                        <WhatsAroundUs />
                        <AboutReginaPacis />

                        <Link className="mt-4" to="/accommodation">
                            <Button text="View our accomodations" />
                        </Link>
                    </div>
                </motion.div>
            </div>

            <motion.div
                className="flex justify-center items-center p-2"
                initial={{ opacity: 1, y: 200 }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: { delay: 0, duration: 0.9 },
                }}
                viewport={{ once: true, amount: 0 }}>
                <div className="grid grid-cols-6 max-sm:grid-cols-2 max-lg:grid-cols-3 gap-4">
                    {
                        images.map((image, index) => {
                            return (
                                <img key={index} className="rounded-md w-[300px] h-[250px]" src={image.source} alt="" title={image.title} loading="eager" width="300" height="250" />
                            )
                        })
                    }
                </div>
            </motion.div>
        </main>
    )
}