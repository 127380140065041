import * as Yup from "yup";

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const EmailFormValidation = Yup.object({
  name: Yup.string().min(2).required("Name is required"),
  email: Yup.string()
    .matches(emailRegex, "Invalid email format")
    .required("Email is required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]/, {
      message: "Please enter valid number",
      excludeEmptyString: true,
    })
    .required("Phone number is required"),
  fieldOfStudy: Yup.string().required("Field of study is required"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
});
