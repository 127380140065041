import { motion } from "framer-motion";

export const Button = ({ text, onClick }) => {
  return (
    <div className="flex justify-center">
      <motion.button
        type="submit"
        onClick={onClick}
        className="text-white focus:outline-none bg-clickable text-xl max-sm:text-xl max-sm:px-5 rounded text-black font-medium rounded-lg px-5 py-3 mb-2"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        {text}
      </motion.button>
    </div>
  );
};
