import { motion } from "framer-motion"
import { Button } from "../components/Button";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async'

export const HomeCard = () => {
    return (
        <>
            <Helmet>
                <title> HOME | Regina Pacis </title>
                <meta name="keywords" content="studentenhuisvesting, studentenkamers, studentenwoning, kamers te huur Leuven, studentenhuis Leuven, studio huren Leuven, studentenkamer Leuven, studentenappartement, studentenresidentie, studentenflat Leuven, kot te huur, kamers voor studenten, goedkope studentenkamers, studentenkoten Leuven, huur studentenkamer, Dirk Boutslaan 64, 3000 Leuven, Belgium, BELGIUM, studentenverblijf, studentenkamer vinden, koten Leuven, studentenstudios, studentenwoonruimte, student housing, student accommodation, student rooms, rooms for rent Leuven, student apartments, student studio, student residence, student flat Leuven, student rental, student housing Leuven, cheap student rooms, student lodgings, student dorms, student living, student flats, student stay, find student room, Leuven student housing, student studios, student living space" />
                <meta name="description" content="Modern student housing with Dyle River views in central Leuven. Enjoy comfort, convenience, and vibrant city life with parks, cafes, and culture just steps away." />
                <link rel="canonical" href="https://www.residentiereginapacis.be/" />
            </Helmet>

            <motion.div
                className="sm:absolute z-30 self-center grow select-none bg-card shadow-xl rounded-md p-4
            max-w-xl
            max-lg:bottom-[-150px]
            5xl:left-[-80px]
            4xl:left-[-50px]
            3xl:left-[-20px]
            2xl:left-[-5px]
            lg:left-0"
                initial={{ opacity: 1, y: 200 }}
                whileInView={{
                    opacity: 1,
                    y: 0,
                    transition: { delay: 0, duration: 0.9 },
                }}
                viewport={{ once: true, amount: 0 }}>
                <h1 className="uppercase font-bold text-gray-700 text-4xl"> Looking for student housing in Leuven? </h1>
                <div className="w-full mb-6 border-2 border-b-[#bb9f82] " />
                <h2 className="text-justify">We have excellent furnished accommodations with great services here in Leuven.
                    Come stay with us and experience the best of both worlds - the comfort of a modern student house and the enchanting atmosphere of Leuven - all within reach of your doorstep.
                </h2>
                <div className="flex justify-center mt-4">
                    <Link to="/accommodation">
                        <Button className="" text="View our accommodations" />
                    </Link>
                </div>
            </motion.div>
        </>
    )
}