import { Link } from "react-router-dom"

export const PageNotFound = () => {
    return (
        <div className="max-w-screen-xl m-auto text-center py-10 flex flex-col gap-4">
            <h1 className="font-bold">PAGE NOT FOUND!</h1>
            <Link to="/" className="bg-yellow-500 w-26 p-4 rounded m-auto text-white text-lg hover:cursor-pointer hover:bg-yellow-600">
                <button>Back To Home</button>
            </Link>
        </div>
    )
}