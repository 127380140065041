import { motion } from "framer-motion"
import { Button } from "./Button"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { PiSpinnerThin } from "react-icons/pi";
import { useEffect } from "react";
import { getRoomCosts } from "../reducers/roomCostsSlice"

export const AboutRoom = ({ roomDetail }) => {
    const detail = roomDetail[0]

    const { isLoading, isError, error, success, roomCosts } = useSelector(
        (state) => state.accommodation
    )

    const dispatch = useDispatch();
    useEffect(() => {
        if (roomCosts.length === 0) {
            dispatch(getRoomCosts());
        }
    }, [dispatch, roomCosts]);


    function getCorrectRoomCosts() {
        if (roomCosts.length === 0) {
            return null;
        } else {
            return roomCosts.filter(room => room.roomType === detail.roomType)[0]
        }
    }

    var costDetails = getCorrectRoomCosts()

    return (
        <motion.div
            className="py-2 pr-4 pl-2 max-sm:p-4"
            initial={{ opacity: 1, y: 100 }}
            whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0, duration: 0.8 },
            }}>
            <div className="flex flex-col gap-2 block w-[500px] h-[840px] max-xl:h-[750px] max-sm:w-[400px] mt-2 p-2 bg-card border border-gray-200 rounded-lg shadow">
                <h5 className="text-2xl text-black font-bold uppercase tracking-tight text-gray-900">{detail.title}</h5>
                <div className="border-2 p-2 border-t-horizontalLine border-transparent rounded">
                    <p className="font-bold uppercase text-gray-700">{detail.features.title}</p>
                    {
                        detail.features.details.map((feature, index) => {
                            return (
                                <div key={index} className="pl-4">
                                    <li>{feature}</li>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="border-2 p-2 border-t-horizontalLine border-transparent rounded">
                    <span className="font-normal inline-flex text-gray-700"><span className="font-bold uppercase">{detail.priceRange.title}</span> :
                        {
                            isLoading ? (
                                <div className="flex justify-center items-center">
                                    <PiSpinnerThin className="animate-spin" size="200px" />
                                </div>
                            ) : isError ? (
                                <div className="flex justify-center flex-col items-left">
                                    <p className="text-sm ml-2 text-rose-700">Couldn't reach server to fetch price.</p>
                                </div>
                            ) : (
                                success &&
                                <span className="text-black ml-2">{costDetails ? costDetails["roomCostRange"] : "PRICE NOT FOUND IN SERVER!"}</span>
                            )
                        }
                    </span>
                </div>

                <div className="border-2 p-2 border-t-horizontalLine border-b-horizontalLine border-transparent rounded">
                    <span className="font-normal inline-flex text-gray-700"><span className="font-bold uppercase">{detail.extra.title}</span> :
                        {
                            isLoading ? (
                                <div className="flex justify-center items-center">
                                    <PiSpinnerThin className="animate-spin" size="200px" />
                                </div>
                            ) : isError ? (
                                <div className="flex justify-center flex-col items-left">
                                    <p className="text-sm ml-2 text-rose-700">Couldn't reach server to fetch price.</p>
                                </div>
                            ) : (
                                success &&
                                <span className="text-black ml-2">{costDetails ? costDetails["utilitiesCost"] : "PRICE NOT FOUND IN SERVER!"}</span>
                            )
                        }
                    </span>

                    {
                        detail.extra.details.map((detail, index) => {
                            return (
                                <div key={index} className="pl-4">
                                    <li>{detail}</li>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="border-2 p-2 border-b-horizontalLine border-transparent rounded">
                    <span className="text-gray-700 inline-flex items-center"><span className="font-bold uppercase">{detail.cityTax.title}</span> :
                        {
                            isLoading ? (
                                <div className="flex justify-center items-center">
                                    <PiSpinnerThin className="animate-spin" size="200px" />
                                </div>
                            ) : isError ? (
                                <div className="flex justify-center flex-col items-center ">
                                    <p className="text-sm ml-2 text-rose-700">Couldn't reach server to fetch price.</p>
                                </div>
                            ) : (
                                success &&
                                <span className="text-black ml-2">{costDetails ? costDetails["cityTaxCost"] : "PRICE NOT FOUND IN SERVER!"}</span>
                            )
                        }
                    </span>
                </div>
                <div className="flex justify-center items-end grow">
                    <Link to="/contact">
                        <Button text="Contact" />
                    </Link>
                </div>
            </div>
        </motion.div>
    )
}