import { motion } from "framer-motion"
import { Button } from "./Button"
import { Link } from "react-router-dom"

export const HomeDescription = () => {
    const images = [
        { source: "/assets/leuven/img1.jpg", title: "Leuven train station" },
        { source: "/assets/leuven/img2.jpg", title: "Leuven Ladeuzeplein" },
        { source: "/assets/leuven/img3.jpg", title: "Leuven Sint-Donatus Park" },
        { source: "/assets/leuven/img4.jpg", title: "Leuven Dyle river" },
        { source: "/assets/leuven/img5.jpg", title: "Klein Begijnhof Leuven" },
        { source: "/assets/leuven/img6.jpg", title: "Leuven Bondgenotenlaan" },
    ];

    return (
        <motion.div
            className="flex flex-col gap-4 p-4 text-lg justify-center items-center mx-auto"
            initial={{ opacity: 1, y: 200 }}
            whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0, duration: 0.9 },
            }}
            viewport={{ once: true, amount: 0 }}>
            <div className="max-w-screen-xl flex flex-col max-lg:mt-36 max-sm:mt-0 gap-4">
                <h2 className="uppercase text-2xl text-center font-bold text-3xl">
                    Welcome to <span className="text-textColor">Residentie Regina Pacis</span>
                </h2>

                <h3>
                    A cozy and modern student housing with romantic/beautiful view of the <strong>Dyle River</strong>. Embrace the vibrant lifestyle of Leuven while residing in this inviting student house.
                    Nestled in the heart of the city, this modern and recently renovated abode offers comfort and convenience at every turn.
                    Leuven’s compact size makes it easy to navigate on foot or by bike, and its efficient public transportation system connects you to neighboring cities and beyond.
                </h3>

                <ul className="list-disc">
                    <li className="mb-2 ml-8">The city’s <strong>numerous parks and green spaces</strong> provide a serene escape.</li>
                    <li className="mb-2 ml-8">While the <strong>Oude Markt’s</strong> bustling social scene ensures there’s never a dull moment.</li>
                    <li className="mb-2 ml-8">Our residences are <strong>within walking distance of Leuven’s vibrant city center</strong>. There you will find a wide range of stores, culture, sports, restaurants, cafes, services.</li>
                </ul>

                <h3 className="">
                    Experience the best of both worlds - the comfort of a modern student house and the enchanting atmosphere of Leuven - all within reach of your doorstep.
                </h3>
            </div>
            <Link to="/about">
                <Button text="Details about us" />
            </Link>

            <div className="grid grid-cols-6 max-sm:grid-cols-2 max-lg:grid-cols-3 gap-4">
                {
                    images.map((image, index) => {
                        return (
                            <img key={index} className="rounded-md w-[300px] h-[250px]" src={image.source} alt="" title={image.title} loading="eager" width="300" height="250" />
                        )
                    })
                }
            </div>
        </motion.div>
    )
}
