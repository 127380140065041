import { Map, EmailForm, ContactDetails } from "../components";

export const Contact = () => {
  return (
    <main>

      <Map />

      <div className="flex justify-center items-center gap-4 mb-2 max-xl:flex-col-reverse">
        <ContactDetails />
        <EmailForm />
      </div>

    </main>
  );
};
