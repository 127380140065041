import { Hero, HomeDescription } from "../components";

export const Home = ({ title }) => {
  return (
    <main>
      <div>
        <section className="relative flex justify-center absolute p-4 grow gap-8 w-full max-w-screen-3xl mx-auto">
          <Hero />
        </section>
      </div>

      <section>
        <HomeDescription />
      </section>
    </main>
  );
};
