export const Map = () => {
  return (
    <div className="w-full flex flex-col justify-center">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6909.321804740109!2d4.697545!3d50.882117!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c16167eb9f386f%3A0xae32671333fad6aa!2sDirk%20Boutslaan%2064%2C%203000%20Leuven!5e1!3m2!1sen!2sbe!4v1714854718661!5m2!1sen!2sbe"
        title="Google Map Location"
        width="100%"
        height="550"
        allowFullScreen=""
        loading="eager"
        referrerPolicy={"no-referrer-when-downgrade"}
      ></iframe>
    </div>
  );
};
